/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
    'use strict';

    /**
     * Helper function to allow setting multiple attributes at a single time in JS
     * @param el - HTML element
     * @param attrs - attribute object
    */
    function setAttributes(el, attrs) {

        for (var key in attrs) {
            el.setAttribute(key, attrs[key]);
        };
    }

    /**
     * Helper function for ariaTabFix function. This function traverses DOM to set child button elements with their proper `tabindex` and `aria-hidden` attrs. Currently searches for `buttons`, `h2` and `a` tags`
     * @param el - HTML element
     * @param active (boolean) - If true, `aria-hidden` will be set to false, and `tabindex` to 0. If false, the reciprocal will be set.
    */
    function addtlAriaTabFix(el, active) {

        var nodeList = el.querySelectorAll('button, a, h2, .pagination-container > div');

        //while not dry, setting the conditional outside of the for loop requires less computation
        if(active) {

            for (var i = 0; i < nodeList.length; i++) {
                setAttributes( nodeList[i], {"aria-hidden": false, "tabindex": 0} );
            }

        } else {

            for (var i = 0; i < nodeList.length; i++) {
                setAttributes( nodeList[i], {"aria-hidden": true, "tabindex": -1} );
            }
        }
    }

    /**
     * a11y functionality for Swiper sliderAccessArgs
     * - automatically set tabindex and aria-hidden attributes for active and inactive slides
     * @param slide - Swiper slider container
    */
    function ariaTabFix(slide) {

        var allSlides = slide.slides;
        var index_currentSlide = slide.realIndex;
        var currentSlide = allSlides[index_currentSlide];

        // console.log(currentSlide);

        //for the current active slide, set aria attrs
        setAttributes( currentSlide, {"aria-hidden": false, "tabindex": 0} );

        //if current slide has a button, then let's set the aria attrs
        if ( currentSlide.querySelectorAll('button').length > 0 ) {

            addtlAriaTabFix(currentSlide, true);
        }

        // console.log(allSlides);
        //for all other slides, set aria to hidden and disable tabindex
        for ( var i = 0; i < allSlides.length; i++) {

            //if the current item is the active slide, simply continue in the loop
            if ( i === index_currentSlide) continue;

            setAttributes( allSlides[i], {"aria-hidden": true, "tabindex": -1} );

            //if slide contains buttons, let's disable aria attrs
            addtlAriaTabFix(allSlides[i], false);
        }
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                //Header ".menu" functionality
                $(".menu").on("click", function () {
                    $(".topnav, .quicklinks-container").toggleClass("show-menu");
                });

                $('.hamburger').on('click', function () {
                    $(this).toggleClass('is-active');
                    $(".topnav").toggleClass("show-menu");
                });

                $(window).resize(function () {
                    $('.topnav').attr('style', '');
                    $('.hamburger').removeClass('is-active');
                });

                // Scroll To
                (function () {
                    $('.scrollto').on('click', function (e) {
                        e.preventDefault();
                        var section = $(this).attr('href');
                        $('html, body').animate({
                            scrollTop: $(section).offset().top
                        }, 1000);
                    });
                })();

                //Lazy Load
                if ($('.lazy-load').length > 0) {
                    $(".lazy-load").each(function (e) {
                        var item = $(this);
                        var waypoint_lazy = new Waypoint({
                            element: item[0],
                            handler: function (direction) {
                                if (direction === "down") {
                                    item.css("opacity", 1);
                                }
                            },
                            offset: '60%'
                        });
                    });
                }
            },
            finalize: function () {
                //On smaller screen widths, menu lists will be collapsed and the user will be able to click on
                // header to expand
                var screenWidth = $(window).width();
                if (screenWidth <= 681) {
                    $(".nav-list ul").addClass("hide-list");
                }

                $('.nav-parent').hover(function () {
                   $(this).attr('aria-expanded', true);
                }, function () {
                    $(this).attr('aria-expanded', false);
                });

                $('#topnav .menu-item').hover(function () {
                    $(this).addClass('open');
                }, function () {
                    $(this).removeClass('open');
                });

                $('#topnav .menu-item button').on('click', function () {
                   $(this).parents('.menu-item').toggleClass('open');
                   if($(this).parents('.menu-item').hasClass('open')) {
                       $(this).attr('aria-expanded', true);
                   } else {
                       $(this).attr('aria-expanded', false);
                   }
                });

                //* Dynamically create nav down arrow for dropdown functionality - mobile and desktop
                $('#topnav .menu-lists .nav-list').each(function () {
                    //grab the <li> that has the ``.nav-header` class to it. This should always be the first child <li>
                    var header = $(this).find('.nav-header');

                    //create button
                    var dropdown_button = document.createElement('button');
                    dropdown_button.className = 'dropdown-menu-button';
                    dropdown_button.setAttribute('aria-expanded', false);
                    dropdown_button.onclick = function () {
                        header.parents('.nav').toggleClass('hide-list');
                        if(header.parents('.nav').hasClass('hide-list')) {
                            $(this).attr('aria-expanded', false);
                        } else {
                            $(this).attr('aria-expanded', true);
                        }
                    };

                    //AXE - add <span> with text to button for screenreaders
                    var dropdown_button_text = document.createElement('span');
                    dropdown_button_text.innerHTML = 'Show Dropdown Menu';
                    dropdown_button.append(dropdown_button_text);

                    //create the dropdown arrow
                    var dropdown_arrow = document.createElement('img');
                    dropdown_arrow.setAttribute('src', window.location.origin + "/wp-content/themes/ucgrad-theme/assets/images/menu-dropdown-arrow.svg");
                    dropdown_arrow.setAttribute('alt', 'dropdown arrow');
                    dropdown_button.append(dropdown_arrow);

                    //now, append the dropdown button to the end of the parent <li>
                    header.append(dropdown_button);
                });

                var touchtime = 0;
                $(".nav-header a").on("click", function () {
                    if (touchtime === 0) {
                        // set first click
                        touchtime = new Date().getTime();
                    } else {
                        // compare first click to this click and see if they occurred within double click threshold
                        if (((new Date().getTime()) - touchtime) < 800) {
                            // double click occurred
                            var screenWidth = $(window).width();
                            if (screenWidth <= 681) {
                                window.location = this.href;
                            }
                            touchtime = 0;
                        } else {
                            // not a double click so set as a new first click
                            touchtime = new Date().getTime();
                        }
                    }
                });

                //Nav Submenu Hover
                $(document).ready(function () {
                    // $('ul.sf-menu').superfish({
                    //     popUpSelector: 'ul,.dropdown-menu',
                    //     cssArrows: false
                    // });
                });

                $('#topnav .nav-parent').on('keyup', function (e) {
                    if (e.which === 38 || e.which === 40) {
                        e.preventDefault();
                        $('#topnav .dropdown-menu').removeClass('show');
                        if (e.which === 40) {
                            $(this).parent().find('.dropdown-menu').addClass('show');
                        } else {
                            $(this).parent().find('.dropdown-menu').removeClass('show');
                        }
                    }
                });

                //Quicklinks Slide In
                if ($('.quicklinks-container').length > 0) {
                    $(".quicklinks-container").addClass('slide-in');

                    $(document).on("scroll", function () {
                        var scrollTop = $(window).scrollTop();
                        if (scrollTop > 0) {
                            $(".quicklinks-container").addClass("fixed");
                            $(".quicklinks-container a").attr('aria-expanded', false);
                            $('.header').addClass('fixed');
                        } else {
                            $(".quicklinks-container").removeClass("fixed");
                            $(".quicklinks-container a").attr('aria-expanded', true);
                            $(".header").removeClass('fixed');
                        }
                    });

                    $(".quicklinks-container a").hover(function () {
                        if ($(this).parent().hasClass('fixed')) {
                            $(this).addClass('hovering');
                            $(this).attr('aria-expanded', true);
                        }
                    }, function () {
                        if ($(this).parent().hasClass('fixed')) {
                            $(this).removeClass('hovering');
                            $(this).attr('aria-expanded', false);
                        }
                    });
                }

                //Set Header Height
                if ($(".page-template-template-home").length === 0) {
                    var topNavHeight = $(".topnav").height();
                    var subMenuHeight = $(".sub-menu").height();
                    $(".header").css("height", topNavHeight + subMenuHeight);
                    $(window).resize(function () {
                        var topNavHeight = $(".topnav").height();
                        var subMenuHeight = $(".sub-menu").height();
                        $(".header").css("height", topNavHeight + subMenuHeight);
                    });
                }

                //Fix Header on Scroll
                $(document).on("scroll", function () {
                    var scrollTop = $(window).scrollTop();
                    var headerHeight = $(".header").height();
                    if (scrollTop > 0) {
                        $('.header').addClass('fixed');
                        $('.main').css("padding-top", headerHeight);
                        if ($(".sitemap").length > 0) {
                            $(".page-header").css("padding-top", headerHeight);
                        }
                    } else {
                        $(".header").removeClass('fixed');
                        $(".main").removeAttr("style");
                        if ($(".sitemap").length > 0) {
                            $(".page-header").removeAttr("style");
                        }
                    }
                });

                //Red arrow sliding link
                if ($('.red-arrow-cta').length > 0) {
                    $('.red-arrow-cta').each(function () {
                        if ($(this).children('p').length > 0) {
                            var totalWidth = $(this).children('p').width() + 50;
                            $(this).css('width', totalWidth);
                            $(this).addClass("show");
                        }
                    });
                }

                //GDPR Message
                $(".agree").on("click", function () {
                    $(".cookie-message").fadeOut();
                });

                //Open Newsletter Confirmation in new tab
                if ($(".newsletter").length > 0) {
                    $(".newsletter form")[0].target = "_blank";
                    $(".newsletter form").submit(function () {
                        setTimeout(function () {
                            if ($("#id_email-error").length > 0) {
                                $(".newsletter").addClass("submitted");
                            } else {
                                $(".newsletter").removeClass("submitted");
                            }
                        }, 100);
                    });
                }

                //Select Field
                if ($(".gfield_select").length > 0) {
                    $(".gfield_seelct").each(function () {
                        $(this).select2();
                    })
                }
            }
        },

        // Home page
        'page_template_template_home': {
            init: function () {
                //Banner Lazy Load
                $(".gargoyle").addClass('slide-in');
                $(".logo, .main-headline").addClass('show');
            },
            finalize: function () {

                //Navigate Gargoyle Slide in
                if ($(window).width() > 868) {
                    var waypoint_gargoyle = new Waypoint({
                        element: $(".help-navigate svg"),
                        handler: function () {
                            $(".help-navigate svg").addClass("slide-in");
                        },
                        offset: '60%'
                    });
                }


                //mobile banner carrot anchor
                var prevScrollTop = 0;

                function scrollToAnchor(aid) {
                    var aTag = $(aid);
                    $('html,body').animate({scrollTop: aTag.offset().top}, 'slow');
                }

                $(".logo a, .logo button.mobile_banner_btn").on("click", function (e) {
                    e.preventDefault();
                    scrollToAnchor("#banner-quicklink-section");
                });

                $(document).on("scroll", function () {
                    var scrollTop = $(window).scrollTop();
                    if ($("#quicklinks-container").hasClass("anchored")) {
                        if (scrollTop < prevScrollTop) {
                            $("#quicklinks-container").removeClass("anchored");
                            window.scrollTo(0, $("#quicklinks-container").offset().top - $(".header").height());
                        }
                        prevScrollTop = scrollTop;
                    }

                });

                //Advantages Slider
                function unBlur() {
                    var items = $(".advantage-container").children(".blur");
                    var parentTop = $(".advantage-container").offset().top;
                    var parentHeight = $(".advantage-container").height();
                    var offset = parentTop + parentHeight - 80;
                    items.each(function () {
                        if ($(this).offset().top <= offset) {
                            $(this).addClass("unblur");
                        } else {
                            $(this).removeClass("unblur");
                        }
                    })
                }

                //Advantage Blur Effect
                $(document).on("scroll", function () {
                    var scrollTop = $(window).scrollTop();
                    if ($(window).width() >= 681) {
                        var parentTop = $(".advantages").offset().top;
                        var windowHeight = $(window).height();
                        var unblurBoxStart = scrollTop + ($(window).height() * .75);
                        var unblurBoxEnd = scrollTop + ($(window).height() * .2);
                        if ($(".blur").length > 0 && (parentTop - windowHeight) <= scrollTop) {
                            $(".blur").each(function () {
                                var advantageTop = $(this).offset().top;
                                var heightToUnblur = windowHeight * .3;
                                if (scrollTop > (advantageTop - windowHeight)) {
                                    {
                                        if (advantageTop > unblurBoxStart) {
                                            var newBlur = (heightToUnblur - (advantageTop - unblurBoxStart));
                                            newBlur = 4 - (newBlur * (1 / (heightToUnblur / 4)));
                                            if (newBlur > 0) {
                                                var finalBlur = "blur(" + newBlur + "px)";
                                                $(this).children().css("filter", finalBlur);
                                                $(this).removeClass("unblur");
                                            }
                                        } else if (advantageTop <= unblurBoxStart && advantageTop > unblurBoxEnd) {
                                            $(this).addClass("unblur");
                                            $(this).children().css("filter", "blur(0px)");
                                        } else if (advantageTop <= unblurBoxStart && advantageTop <= unblurBoxEnd) {
                                            var newBlur = (heightToUnblur - (unblurBoxEnd - advantageTop));
                                            newBlur = 4 - (newBlur * (1 / (heightToUnblur / 4)));
                                            if (newBlur <= 4) {
                                                var finalBlur = "blur(" + newBlur + "px)";
                                                $(this).children().css("filter", finalBlur);
                                                $(this).removeClass("unblur");
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    }
                });

                //Image Gallery Slider
                var gallery = new Swiper('.image-gallery .swiper-container', {
                    speed: 1000,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    navigation: {
                        nextEl: '.gallery-next',
                        prevEl: '.gallery-prev'
                    },
                    on: {
                        init: function () {
                            ariaTabFix( this );
                        },
                        slideChange: function () {
                            ariaTabFix( this );
                        }
                    }
                });

                $(".image-gallery .caption p").html($(".image-gallery .swiper-slide-active")[0].dataset.caption);

                gallery.on("slideChange", function () {
                    $(".image-gallery .caption p").fadeOut();
                    setTimeout(function () {
                        $(".image-gallery .caption p").html($(".image-gallery .swiper-slide-active")[0].dataset.caption);
                        $(".image-gallery .caption p").fadeIn();
                    }, 500);
                });

                $('.image-gallery .pause-play').on('click', function () {
                    $(this).toggleClass('paused');
                    if ($(this).hasClass('paused')) {
                        $(this).find('.pause-btn-label').html('Play');
                        gallery.autoplay.stop();
                    } else {
                        $(this).find('.pause-btn-label').html('Pause');
                        gallery.autoplay.start();
                    }
                });

                //Testimonial Slider
                var testimonials = new Swiper('.testimonials .swiper-container', {
                    speed: 1000,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    navigation: {
                        nextEl: '.testimonial-next',
                        prevEl: '.testimonial-prev'
                    },
                    on: {
                        init: function () {
                            ariaTabFix( this );
                        },
                        slideChangeTransitionEnd: function () {
                            ariaTabFix( this );
                        }
                    }
                });

                function setTestimonialSwiperHeight() {
                    setTimeout(function () {
                        var slideHeight = parseFloat($(".swiper-slide-active")[1].dataset.height) + 50;
                        $(".testimonials .swiper-container").css("height", slideHeight);
                    }, 100);
                }

                function getInitalHeights() {
                    $(".swiper-slide").each(function () {
                        var slide = $(this);
                        var height = $(this).children(".quote").height();
                        slide[0].dataset.height = height;
                    });
                }

                testimonials.on("slideChange", function () {
                    setTestimonialSwiperHeight();
                });

                $(document).ready(function () {
                    getInitalHeights();
                    setTestimonialSwiperHeight();
                });

                $(window).resize(function () {
                    setTimeout(function () {
                        getInitalHeights();
                    }, 10);
                });

                $('.testimonials .pause-play').on('click', function () {
                    $(this).toggleClass('paused');
                    if ($(this).hasClass('paused')) {
                        $(this).find('.pause-btn-label').html('Play');
                        testimonials.autoplay.stop();
                    } else {
                        $(this).find('.pause-btn-label').html('Pause');
                        testimonials.autoplay.start();
                    }
                });
            }
        },

        'page_template_template_admissions_landing': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Testimonial Slider
                var testimonials = new Swiper('.testimonials .swiper-container', {
                    navigation: {
                        nextEl: '.next',
                        prevEl: '.prev'
                    },
                    on: {
                        init: function () {
                            ariaTabFix( this );
                        },
                        slideChange: function () {
                            ariaTabFix( this );
                        }
                    }
                });

                //Resize swiper container on side change
                function setTestimonialSwiperHeight() {
                    setTimeout(function () {
                        var slideHeight = parseFloat($(".swiper-slide-active")[0].dataset.height) + 50;
                        $(".testimonials .swiper-container").css("height", slideHeight);
                    }, 100);
                }

                function getInitalHeights() {
                    $(".swiper-slide").each(function () {
                        var slide = $(this);
                        slide[0].dataset.height = $(this).children(".quote").height();
                    })
                }

                testimonials.on("slideChange", function () {
                    setTestimonialSwiperHeight();
                });

                $(document).ready(function () {
                    getInitalHeights();
                    setTestimonialSwiperHeight();
                });

                $(window).resize(function () {
                    setTimeout(function () {
                        getInitalHeights();
                        setTestimonialSwiperHeight();
                    }, 10)
                });

                //Center Offerings on resize
                function adjustOfferingContainerWidth() {
                    var sectionWidth = $(".key-offerings").width();
                    var offeringWidth = $(".offering").width() + 45;
                    var newWidth = offeringWidth * Math.floor(sectionWidth / offeringWidth);
                    if ($(window).width() > 581) {
                        $(".offerings-container").css("width", newWidth);
                    }
                }

                if ($(".offering").length > 0) {
                    $(window).resize(function () {
                        adjustOfferingContainerWidth();
                    });

                    $(document).ready(function () {
                        adjustOfferingContainerWidth();
                    });
                }
            }
        },

        'page_template_template_admissions_secondary': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // Testimonial Slider
                var testimonials = new Swiper('.testimonials .swiper-container', {
                    navigation: {
                        nextEl: '.next',
                        prevEl: '.prev'
                    },
                    on: {
                        init: function () {
                            ariaTabFix( this );
                        },
                        slideChange: function () {
                            ariaTabFix( this );
                        }
                    }
                });

                //Resize swiper container on side change
                function setTestimonialSwiperHeight() {
                    setTimeout(function () {
                        var slideHeight = parseFloat($(".swiper-slide-active")[0].dataset.height) + 50;
                        $(".testimonials .swiper-container").css("height", slideHeight);
                    }, 100);
                }

                function getInitalHeights() {
                    $(".swiper-slide").each(function () {
                        var slide = $(this);
                        slide[0].dataset.height = $(this).children(".quote").height();
                    })
                }

                testimonials.on("slideChange", function () {
                    setTestimonialSwiperHeight();
                });

                $(document).ready(function () {
                    getInitalHeights();
                    setTestimonialSwiperHeight();
                });

                $(window).resize(function () {
                    setTimeout(function () {
                        getInitalHeights();
                        setTestimonialSwiperHeight();
                    }, 10)
                });

                //Center Offerings on resize
                function adjustOfferingContainerWidth() {
                    var sectionWidth = $(".key-offerings").width();
                    var offeringWidth = $(".offering").width() + 45;
                    var newWidth = offeringWidth * Math.floor(sectionWidth / offeringWidth);
                    if ($(window).width() > 581) {
                        $(".offerings-container").css("width", newWidth);
                    }
                }

                if ($(".offering").length > 0) {
                    $(window).resize(function () {
                        adjustOfferingContainerWidth();
                    });

                    $(document).ready(function () {
                        adjustOfferingContainerWidth();
                    });
                }
            }
        },

        'page_template_template_secondary_page': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                //FAQ List Functionality
                if ($(".question").length > 0) {
                    $(".question").on("click", function () {
                        $(this).parent().children(".answer").toggleClass("show");
                        $(this).children('svg').toggleClass('show');
                    });
                }

                //Gallery Slider
                if ($(".gallery").length > 0) {
                    var galley = new Swiper('.gallery .swiper-container', {
                        pagination: {
                            el: '.pagination',
                            type: 'fraction'
                        },
                        navigation: {
                            nextEl: '.next',
                            prevEl: '.prev'
                        },
                        autoHeight: true,
                        on: {
                            init: function () {
                                ariaTabFix( this );
                            },
                            slideChange: function () {
                                ariaTabFix( this );
                            }
                        }
                    });
                }

                //Form Submit Hover Effect
                if ($(".gform_footer").length > 0) {
                    var buttonWidth = $(".gform_footer").children(".gform_button").width() + 40;
                    $(".gform_footer").css("width", buttonWidth);
                }

                //Style Select Form Input
                if ($(".gfield_select").length > 0) {
                    $(document).ready(function () {
                        $(".gfield_select").select2();
                    });
                }

                //Page Tab Functionality
                if ($(".tabs").length > 0) {
                    setTimeout(function () {
                        var url_string = window.location.href;
                        var url = new URL(url_string);
                        var tabSection = url.searchParams.get("tab-section");
                        $(".tab-section").css({
                            opacity: "1"
                        });
                        $(".tab-section").each(function () {
                            if (tabSection !== null) {
                                if ($(this)[0].id !== tabSection) {
                                    $(this).css({
                                        display: "none"
                                    });
                                }
                            } else {
                                $(this).css({
                                    display: "none"
                                })
                            }
                        });
                        if (tabSection !== null) {
                            $("#home-section").css("display", "none");
                        }
                    }, 50);
                }
                //inner tab click functionality
                $(".tab-btn").on("click", function () {

                    var tab_id, tab_id_clean; 
                    tab_id = tab_id_clean = $(this)[0].dataset.tabSection;
                    tab_id = "#" + tab_id;
                    
                    $(".modules-container").css("display", "none").attr('hidden', 'hidden');
                    $(tab_id).css("display", "block").removeAttr('hidden');
                    $(".tab-btn").removeClass("active");
                    $(this).addClass("active");
                    $(".tab-btn").attr('aria-selected', 'false');
                    $(this).attr('aria-selected', 'true');

                    //* Append the URL to include the tab content params for easy link sharing
                    var url_string = window.location.href;
                    var url = new URL(url_string);
                    var params = new URLSearchParams(url.search);
                    var getParams = params.get("tab-section");

                    if(getParams) {

                        //tab section exists. We should only change the param if it's new
                        if(getParams != tab_id_clean) {

                            params.set('tab-section', tab_id_clean);

                            // params.toString();
                            // url.search = params;
                            // url.toString();

                            // Replace current querystring with the new one
                            history.replaceState(null, null, "?" + params.toString());
                        }

                    } else {
                        //a tabsection is not set, so let's set it

                        params.set('tab-section', tab_id_clean);

                        // Replace current querystring with the new one
                        history.replaceState(null, null, "?" + params.toString());
                    }

                });

                //Neighborhood Map
                function setMap() {

                    var margin = 20,
                        width = 500,
                        height = 615,
                        mWidth = width - margin * 2;

                    var map = d3.select("#mapBox")
                        .append("svg")
                        .attr("id", "map")
                        .attr("preserveAspectRatio", "xMidYMid")
                        .attr("viewBox", "0 0 " + width + " " + (height + 85))

                    var projection = d3.geo.transverseMercator()
                        .center([0, 41.830])
                        .rotate([87.73, 0])
                        .scale(90000)
                        .translate([width / 2, height / 2]);

                    var path = d3.geo.path()
                        .projection(projection);

                    queue()
                        .defer(d3.csv, zipAreaFile)
                        .defer(d3.json, window.location.origin + "/wp-content/themes/ucgrad-theme/assets/scripts/lib/d3/zipAreas.json")
                        .await(callback);

                    function callback(error, csvData, zipData) {

                        var recolorMap = colorScale(csvData);

                        var jsonRegions = zipData.objects.zipAreas.geometries;

                        for (var i = 0; i < csvData.length; i++) {
                            var csvRegion = csvData[i];
                            var csvZipArea = csvRegion.zip; //join at "zip"

                            for (var a = 0; a < jsonRegions.length; a++) {

                                if (jsonRegions[a].properties.zip == csvZipArea) {

                                    for (var key in keyArray) {
                                        var attr = keyArray[key];
                                        var val = parseFloat(csvRegion[attr]);
                                        jsonRegions[a].properties[attr] = val;
                                    }
                                    ;

                                    jsonRegions[a].properties.neighborhoods = csvRegion.neighborhoods;
                                    break;
                                }
                                ;
                            }
                            ;
                        }
                        ;
                        //Accessibility - Do not dynamically set incoming data as an ID. There have been instances where there are duplicates, which causes an accessibility demerit.
                        var regions = map.selectAll(".regions")
                            .data(topojson.feature(zipData, zipData.objects.zipAreas).features)
                            .enter()
                            .append("path")
                            .attr("class", "regions")
                            .attr("data-zip", function (d) {
                                return d.properties.zip
                            })
                            .attr("data-neighborhood", function (d) {
                                return d.properties.neighborhoods
                            })
                            .attr("d", path)
                            .on("mouseover", function (d) {
                                var xPosition = d3.select(this).attr("x");
                                var yPosition = d3.select(this).attr("y");


                                if ((d.properties[expressed]) >= 1 && (d.properties[expressed]) <= 5) {
                                    var studentNo = "1 - 5";
                                } else if ((d.properties[expressed]) >= 6 && (d.properties[expressed]) <= 20) {
                                    var studentNo = "6 - 20";
                                } else if ((d.properties[expressed]) >= 21 && (d.properties[expressed]) <= 50) {
                                    var studentNo = "21 - 50";
                                } else if ((d.properties[expressed]) >= 51 && (d.properties[expressed]) <= 100) {
                                    var studentNo = "51 - 100";
                                } else if ((d.properties[expressed]) > 100) {
                                    var studentNo = "More than 100";
                                } else {
                                    var studentNo = "No"
                                }
                                d3.select("#tooltip")
                                    .style("top", function () {
                                        var topVal = d3.mouse(document.getElementById("mapBox"))[1] - 25;
                                        return topVal + "px";
                                    })
                                    .style("left", function () {
                                        var leftVal = d3.mouse(document.getElementById("mapBox"))[0];
                                        if ($(window).width() <= 868) {
                                            leftVal = leftVal / 2;
                                        }
                                        return leftVal + "px";
                                    })
                                    .style("z-index", "1000")
                                    .select("#tipLabel")
                                    .html("<span>" + studentNo + " student(s)</span>" + "<br><strong>Neighborhoods:</strong> " + d.properties.neighborhoods + "<br><strong>Zip code: </strong>" + d.properties.zip);

                                d3.select("#tooltip").classed("hidden", false);

                            })
                            .on("mouseout", function () {
                                d3.select("#tooltip").classed("hidden", true);

                            })
                            .style("fill", function (d) {
                                return choropleth(d, recolorMap);
                            })
                            .append("desc")
                            .text(function (d) {
                                return choropleth(d, recolorMap);
                            });

                        createDropdown(csvData);

                    };

                };

                function createDropdown(csvData) {

                    var dropdown = d3.select("#dropdownBox")
                        .append("div")
                        .attr("class", "dropdown")
                        .html("<h5>Select a school:</h5>")
                        .append("select")
                        .on("change", function () {
                            changeAttribute(this.value, csvData)
                        });
                    dropdown.selectAll("options")
                        .data(keyArray)
                        .enter()
                        .append("option")
                        .attr("value", function (d) {
                            return d
                        })
                        .attr("class", "map-option")
                        .text(function (d) {
                            d = d[0].toUpperCase() + d.substring(1, 3) + d.substring(3);
                            return d
                        });
                    $("#dropdownBox select").select2();
                    $("#dropdownBox select").on("change", function () {
                        changeAttribute($(this).val(), csvData)
                    })
                };

                function colorScale(csvData) {
                    var color = d3.scale.quantile()
                        .domain([1, 6, 21, 51, 100, 4838])
                        .range(["#e1cece", "#d4b4b4", "#b06767", "#800000", "#4d0000"]);
                    return color;
                };

                function choropleth(d, recolorMap) {

                    var value = d.properties[expressed];

                    if (value) {
                        return recolorMap(value);
                    } else {
                        return "#ccc";
                    }
                    ;
                };

                function changeAttribute(attribute, csvData) {

                    expressed = attribute;

                    d3.selectAll(".regions")
                        .style("fill", function (d) {
                            return choropleth(d, colorScale(csvData));
                        })
                        .select("desc")
                        .text(function (d) {
                            return choropleth(d, colorScale(csvData));
                        });
                };
                if ($("#mapBox").length > 0) {
                    //array to adjust all options inside of the "select a school" dropdown. These need to match the CSV columns names.
                    var keyArray = ["all", "Biological Sciences", "Physical Sciences", "Humanities", "Social Sciences", "Booth School of Business", "Divinity School", "Law School", "Pritzker School of Medicine", "Harris School of Public Policy", "Pritzker School of Molecular Engineering", "Graham School", "Crown Family School of Social Work, Policy and Practice"];
                    var expressed = keyArray[0];
                    setMap();
                    $("#dropdownBox .select2-selection__rendered").on("change", function () {
                        console.log("changed");
                    });
                }
            }
        },

        'page_template_template_about': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                //Team Filter
                var url_string = window.location.href;
                var url = new URL(url_string);
                var department = url.search;
                var urlArr = department.split("=");
                if (urlArr[0] === "?department") {
                    department = urlArr[1];
                    var team = $(".staff-member");
                    team.each(function () {
                        if (department === $(this).data("filter").toLowerCase()) {
                            $(this).show();
                        } else {
                            $(this).hide();
                        }
                    });
                }

                $(".filter button").on("click", function () {
                    var filter = $(".team-filter").find(":selected").val();
                    if (filter === "all") {
                        $(".staff-member").show();
                    } else {
                        var team = $(".staff-member");
                        team.each(function () {
                            if (filter.toLowerCase() === $(this).data("filter").toLowerCase()) {
                                $(this).show();
                            } else {
                                $(this).hide();
                            }
                        });
                    }
                });

                //Style Select Options
                $(document).ready(function () {
                    $("#team-filter").select2();
                });

                //Account for header on team section anchor
                var url = window.location.href;
                if (url.indexOf("#team") !== -1) {
                    var topNavHeight = $('.topnav').height();
                    var subMenuHeight = $('.sub-menu').height();
                    var paddingTop = topNavHeight + subMenuHeight;
                    $("#team").css("padding-top", paddingTop);
                    $("#team").addClass("anchored");
                }

                $(document).on("scroll", function () {
                    var scrollTop = $(window).scrollTop();
                    if ($("#team").hasClass("anchored")) {
                        if (scrollTop <= ($("#team").offset().top - 9)) {
                            $("#team").removeAttr("style");
                            window.scrollTo(0, ($("#team").offset().top - $("header").height() - 18));
                            $("#team").removeClass("anchored");
                        }
                    }
                });

                //Center Team members
                function adjustTeamContainerWidth() {
                    var sectionWidth = $(".team-container").width();
                    var teamMemberWidth = $(".team-member").width() + 15;
                    var newWidth = teamMemberWidth * Math.floor(sectionWidth / teamMemberWidth);
                    if ($(window).width() > 581) {
                        $(".offerings-container").css("width", newWidth);
                    }
                }

                if ($(".offering").length > 0) {
                    $(window).resize(function () {
                        adjustOfferingContainerWidth();
                    });

                    $(document).ready(function () {
                        adjustOfferingContainerWidth();
                    });
                }
            }
        },

        'page_template_template_career': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                //Career Checklist Slide
                function careerSwiper() {
                    var planning = new Swiper('.swiper-container', {
                        navigation: {
                            nextEl: '.next',
                            prevEl: '.prev'
                        },
                        breakpoints: {
                            581: {
                                pagination: {
                                    el: '.swiper-pagination',
                                    type: 'fraction'
                                }
                            }
                        },
                        on: {
                            init: function () {
                                ariaTabFix( this );
                            },
                            slideChangeTransitionEnd: function () {
                                ariaTabFix( this );
                            }
                        }
                    });

                    // Add Label to swiper pagination
                    planning.on('slideChange', function () {
                        //Set previous slide nav buttons to check marks
                        setTimeout(function () {
                            var active = $(".swiper-pagination-bullet-active");
                            active.prevAll().addClass("prev-nav");
                            active.removeClass("prev-nav");
                            active.nextAll().removeClass("prev-nav");
                        }, 1);
                        //Set background image on slide change
                        setTimeout(function () {
                            var background = $(".swiper-wrapper").children('.swiper-slide-active')[0].dataset.img;
                            background = "url(" + background + ")";
                            $('.content-container').css('background-image', background);
                        }, 1);
                    });

                    $(".swiper-container").on("click", ".swiper-pagination .swiper-pagination-bullet .box", function () {
                        $(this).parent().toggleClass("clicked");
                        var index = parseFloat($(this).parent().attr('data-index')) - 1;
                        planning.slideTo(index);
                        setTimeout(function () {
                            $('.swiper-slide-active').find('h2').focus();
                        }, 500);
                    });

                    $('.swiper-pagination-bullet button').on('focus', function () {
                        $(this).parent().addClass('focus');
                    });

                    $('.swiper-pagination-bullet button').on('blur', function () {
                        $(this).parent().removeClass('focus');
                    });
                }

                careerSwiper();
                $(window).resize(function () {
                    careerSwiper();
                });

            }
        },

        'page_template_template_program_search': {
            init: function () {
                //JavaScript to be fired on all pages
                var programNum = 0;
                $(".program").each(function () {
                    if ($(this).css("display") !== "none") {
                        programNum++;
                    }
                });

                $(".total-programs .number").html(programNum);
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                //Filter List Functionality, Show or Hide List
                $(".filters-section div h3").on("click", function () {
                    $(this).children("svg").toggleClass("arrow-up");
                    var list = $(this).parent().children("ul");
                    list.toggleClass("show-list");
                });

                // Toggle Between Table and Tiled Styles
                $(".list-style-options svg").on("click", function () {
                    if (!$(this).hasClass("active-list-style")) {
                        $(".list-style-options svg").removeClass("active-list-style");
                        $(this).toggleClass("active-list-style");
                    }

                    if ($(".tiles-button").hasClass("active-list-style")) {
                        $(".programs-container").removeClass("tabled");
                        $(".programs-container").addClass("tiled");
                        setTimeout(function () {
                            adjustProgramsContainerWidth();
                        }, 250);
                    } else if ($(".list-button").hasClass("active-list-style")) {
                        $(".programs-container").addClass("tabled");
                        $(".programs-container").removeClass("tiled");
                        setTimeout(function () {
                            adjustProgramsContainerWidth();
                        }, 250);
                    }
                });

                //Filter Functionality
                $(".filters-container li").on("click", function () {
                    $(this).toggleClass("active-filter");
                });

                $(".filters-container li label").on("click", function () {
                    $(this).parent().toggleClass("active-filter");
                });


                $(".submit").on("click", function (e) {
                    e.preventDefault();
                    var degrees = $(".degree-filters ul").children(".active-filter");
                    var divisions = $(".division-filters ul").children(".active-filter");
                    var filters = [];
                    var divisionFilters = [];
                    var programNum = 0;
                    if (degrees.length > 0 && divisions.length === 0) {
                        degrees.each(function () {
                            filters.push($(this).data("filter"));
                        })
                    } else if (divisions.length > 0 && degrees.length === 0) {
                        divisions.each(function () {
                            filters.push($(this).data("filter"));
                        })
                    } else if (degrees.length > 0 && divisions.length > 0) {
                        degrees.each(function () {
                            var degree = $(this).data("filter");
                            divisions.each(function () {
                                filters.push(degree);
                                divisionFilters.push($(this).data("filter"));
                            })
                        })
                    }
                    if (filters.length > 0) {
                        var programs = $(".program").removeClass('active');
                        $(".program").hide();
                        programs.each(function () {
                            for (var i = 0; i < filters.length; i++) {
                                if ($(this).data("filter").indexOf(filters[i]) !== -1) {
                                    $(this).addClass('active');
                                }
                            }
                        })

                        if (divisionFilters.length) {
                            var programs = $(".program.active");
                            programs.each(function () {
                                for (var i = 0; i < divisionFilters.length; i++) {
                                    if ($(this).data("filter").indexOf(divisionFilters[i]) !== -1) {
                                        $(this).show();
                                    }
                                }
                            })
                        } else {
                            $(".program.active").show();
                        }
                    } else {
                        $(".program").show();
                    }

                    //Update number of programs
                    $(".program").each(function () {
                        if ($(this).css("display") !== "none") {
                            programNum++;
                        }
                    });

                    $(".total-programs .number").html(programNum);
                    tableColorPattern();
                });

                $(".clear").on("click", function () {
                    $(".filters-container li").removeClass("active-filter");
                    $(".program").show();
                    $(".total-programs .number").html($(".program").length);
                    tableColorPattern();
                });

                //Set Table Color Pattern
                function tableColorPattern() {
                    $(".program").removeClass("even-item");
                    $(".program").removeClass("odd-item");
                    var visiblePrograms = $(".program:visible");
                    visiblePrograms.sort(function (a, b) {
                        return $(a).children(".program-name").text().toUpperCase().localeCompare($(b).children(".program-name").text().toUpperCase());
                    });
                    visiblePrograms.each(function (i) {
                        if (((i + 1) % 2) == 0) {
                            $(this).addClass("even-item");
                        } else {
                            $(this).addClass("odd-item");
                        }
                    });
                }

                //Center Programs on Resize
                function adjustProgramsContainerWidth() {
                    var sectionWidth = $(".programs-section").width();
                    var programWidth = $(".program").width() + 70;
                    var newWidth = programWidth * Math.floor(sectionWidth / programWidth);
                    if ($(".programs-container").hasClass("tiled") && $(window).width() > 581) {
                        $(".programs-container").css("width", newWidth);
                    } else {
                        $(".programs-container").removeAttr("style");
                    }
                }

                $(window).resize(function () {
                    adjustProgramsContainerWidth();
                });

                $(document).ready(function () {
                    adjustProgramsContainerWidth();
                });

            }
        },

        'page_template_template_fellowship_database': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired
                //Select2 setup
                //Filter Dropdown
                $(".checkbox-list-button").on("click", function (e) {
                    e.preventDefault();
                    $(this).parent().siblings().removeClass("open");
                    $(this).parent().toggleClass("open");
                    if ($(this).parent().hasClass('open')) {
                        $(this).attr('aria-expanded', true);
                    } else {
                        $(this).attr('aria-expanded', false);
                    }
                });

                //Keyboard Accessible
                $(".checkbox-list-button").bind("keyup", function (e) {
                    var keyCode = e.keyCode;
                    e.preventDefault();
                    if (keyCode === 13) {
                        $(this).parent().siblings().removeClass("open");
                        $(this).parent().toggleClass("open");
                        if ($(this).parent().hasClass('open')) {
                            $(this).attr('aria-expanded', true);
                            $($(this).next().find('.checkbox-option')[0]).focus();
                            $($(this).next().find('.checkbox-option')[0]).parent().addClass('focus');
                        } else {
                            $(this).attr('aria-expanded', false);
                        }
                    } else if (keyCode === 40) {
                        $(this).parent().siblings().removeClass("open");
                        $(this).parent().addClass("open");
                        $(this).attr('aria-expanded', true);
                        $($(this).next().find('.checkbox-option')[0]).focus();
                        $($(this).next().find('.checkbox-option')[0]).parent().addClass('focus');
                    }
                });

                $('.checkbox-list input[type="checkbox"]').bind('keyup', function (e) {
                    var keyCode = e.keyCode;
                    e.preventDefault();
                    $('.checkbox-list .option').removeClass('focus');
                    if (keyCode === 38 && $(this).parent().prev().length > 0) {
                        $(this).parent().prev().addClass('focus');
                        $(this).parent().prev().find('input').focus();

                    } else if (keyCode === 40 && $(this).parent().next().length > 0) {
                        $(this).parent().next().addClass('focus');
                        $(this).parent().next().find('input').focus();
                    } else if (keyCode === 13) {
                        $(this).click();
                    } else if (keyCode === 37 && $(this).parents('fieldset').prev().length > 0) {
                        $(this).parents('fieldset').removeClass('open');
                        $(this).parents('fieldset').prev().addClass('open');
                        $($(this).parents('fieldset').prev().find('.checkbox-option')[0]).focus();
                        $($(this).parents('fieldset').prev().find('.checkbox-option')[0]).parent().addClass('focus');
                    } else if (keyCode === 39 && $(this).parents('fieldset').next().length > 0) {
                        $(this).parents('fieldset').removeClass('open');
                        $(this).parents('fieldset').next().addClass('open');
                        $($(this).parents('fieldset').next().find('.checkbox-option')[0]).focus();
                        $($(this).parents('fieldset').next().find('.checkbox-option')[0]).parent().addClass('focus');
                    } else if (keyCode === 27) {
                        $(this).parents('fieldset').removeClass('open');
                        $(this).parents('fieldset').find('.checkbox-list-button').focus();
                    }
                });

                window.addEventListener("keydown", function (e) {
                    var keyCode = e.keyCode;
                    if ($(e.target).hasClass('checkbox-option') || $(e.target).hasClass('checkbox-list-button')) {
                        if (keyCode === 38 || keyCode === 40 || keyCode === 13) {
                            e.preventDefault();
                        }
                    }
                }, false);

                //Close Dropdown when clicked outside
                $(document).on('click', function (event) {
                    if (!$(event.target).is(".checkbox-list-button, .checkbox-list-button, .option, label, .option input")) {
                        $("fieldset").removeClass("open");
                    }
                });

                //Filter checkbox styling
                $(".option label").on("click", function () {
                    var options = $(this).parent().parent().children(".option").children("input");
                    var checkbox = $(this).parent().parent().parent().children(".checkbox-list-button").children("span");
                    var num = 0;
                    setTimeout(function () {
                        options.each(function () {
                            if ($(this)[0].checked) {
                                num = num + 1;
                            }
                        });
                        if (num > 0) {
                            checkbox.addClass("has-filters");
                            checkbox.html(num + " Selected");
                        } else {
                            checkbox.removeClass("has-filters");
                            checkbox.html("Select");
                        }
                    }, 1);
                });

                $(".option input").on("keyup", function () {
                    var options = $(this).parent().parent().children(".option").children("input");
                    var checkbox = $(this).parent().parent().parent().children(".checkbox-list-button").children("span");
                    var num = 0;
                    setTimeout(function () {
                        options.each(function () {
                            if ($(this)[0]['checked']) {
                                num = num + 1;
                            }
                        });
                        if (num > 0) {
                            checkbox.addClass("has-filters");
                            checkbox.html(num + " Selected");
                        } else {
                            checkbox.removeClass("has-filters");
                            checkbox.html("Select");
                        }
                    }, 1);
                });

                //Set Select Label on Load
                $(document).ready(function () {
                    var selectBoxes = $(".checkbox-list-button");
                    selectBoxes.each(function () {
                        var inputs = $(this).parent().children(".checkbox-list").children(".option").children("input");
                        var num = 0;
                        inputs.each(function () {
                            if ($(this)[0]['checked']) {
                                num = num + 1;
                            }
                        });
                        if (num > 0) {
                            $(this).children("span").addClass("has-filters");
                            $(this).children("span").html(num + " Selected");
                        }
                    })
                });

                //Clear All Functionality
                $(".button-field .clear").on("click", function () {
                    $(".option input").removeAttr("checked");
                    $(".checkbox-list-button span").html("Select");
                    $(".checkbox-list-button span").removeClass("has-filters");
                });

                //Mobile expand filters
                $('.toggle-filters').click(function () {
                    $(this).toggleClass('open');
                    $(".filters form, .fellowships").toggleClass("show-filters");
                });

                $('.close').on('click', function () {
                    $("#hamburger-button").removeClass("open");
                });

                //Sorting Table

                //Sort by Fellowship Name
                $(".fellowship-sort").on("click", function () {
                    var i, td1, td2, switching, shouldSwitch;
                    switching = true;
                    while (switching) {
                        switching = false;
                        shouldSwitch = false;
                        var rows = $("tr");
                        for (i = 1; i < (rows.length - 1); i++) {
                            td1 = rows[i].getElementsByTagName("td")[0].getElementsByTagName("a")[0];
                            td2 = rows[i + 1].getElementsByTagName("td")[0].getElementsByTagName("a")[0];
                            if (td1.text.toLowerCase() > td2.text.toLowerCase()) {
                                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                                shouldSwitch = true;
                                break;
                            }
                        }
                        if (shouldSwitch) {
                            switching = true;
                        }
                    }
                    $(".deadline-sort").attr('aria-sort', 'none');
                    $(this).attr('aria-sort', 'ascending');
                });

                $(".fellowship-sort").on("keyup", function (e) {
                    var keyCode = e.keyCode;
                    if (keyCode === 13) {
                        var i, td1, td2, switching, shouldSwitch;
                        switching = true;
                        while (switching) {
                            switching = false;
                            shouldSwitch = false;
                            var rows = $("tr");
                            for (i = 1; i < (rows.length - 1); i++) {
                                td1 = rows[i].getElementsByTagName("td")[0].getElementsByTagName("a")[0];
                                td2 = rows[i + 1].getElementsByTagName("td")[0].getElementsByTagName("a")[0];
                                if (td1.text.toLowerCase() > td2.text.toLowerCase()) {
                                    rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                                    shouldSwitch = true;
                                    break;
                                }
                            }
                            if (shouldSwitch) {
                                switching = true;
                            }
                        }
                        $(".deadline-sort").attr('aria-sort', 'none');
                        $(this).attr('aria-sort', 'ascending');
                    }
                });

                //Sort by Date
                $(".deadline-sort").on("click", function () {
                    var i, td1, td2, switching, shouldSwitch;
                    switching = true;
                    while (switching) {
                        switching = false;
                        shouldSwitch = false;
                        var rows = $("tr");
                        for (i = 1; i < (rows.length - 1); i++) {
                            td1 = rows[i].getElementsByTagName("td")[1].innerHTML;
                            td2 = rows[i + 1].getElementsByTagName("td")[1].innerHTML;
                            if (td1 > td2) {
                                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                                shouldSwitch = true;
                                break;
                            }
                        }
                        if (shouldSwitch) {
                            switching = true;
                        }
                    }
                    $(".fellowship-sort").attr('aria-sort', 'none');
                    $(this).attr('aria-sort', 'ascending');
                });

                $(".deadline-sort").on("keyup", function (e) {
                    var keyCode = e.keyCode;
                    if (keyCode === 13) {
                        var i, td1, td2, switching, shouldSwitch;
                        switching = true;
                        while (switching) {
                            switching = false;
                            shouldSwitch = false;
                            var rows = $("tr");
                            for (i = 1; i < (rows.length - 1); i++) {
                                td1 = rows[i].getElementsByTagName("td")[1].innerHTML;
                                td2 = rows[i + 1].getElementsByTagName("td")[1].innerHTML;
                                if (td1 > td2) {
                                    rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                                    shouldSwitch = true;
                                    break;
                                }
                            }
                            if (shouldSwitch) {
                                switching = true;
                            }
                        }
                        $(".fellowship-sort").attr('aria-sort', 'none');
                        $(this).attr('aria-sort', 'ascending');
                    }
                })
            }
        },

        'single_fellowship': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                if ($().width() <= 581) {
                    $(".list-header").on("click", function () {
                        $(this).parent().toggleClass("open-list");
                    })
                }
            }
        },

        'page_template_template_event_calendar': {
            init: function () {
                //JavaScript to be fired on all pages

            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                //Symplicity Integration
                $(document).ready(function () {
                    var eventsArr = [];
                    var eventsList = events;
                    eventsList.sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    });
                    eventsList.forEach(function (element) {
                        var event = {};
                        event.date = element.date;
                        event.time = element.time;
                        event.city = element.city;
                        event.title = element.title;
                        event.url = element.url;
                        if (element.featured) {
                            event.featured = " featured";
                            event.image = element.image;
                            event.image_alt = element.image_alt;
                            event.cta_text = element.cta_text;
                            event.time_end = element.time_end;
                        } else {
                            event["featured"] = "";
                        }
                        eventsArr.push(event);
                    });
                    createCalendar(eventsArr);
                    styleRedArrowLink();
                    clndrEvents();
                });

                //Style all links with red arrows so hover effect will work
                function styleRedArrowLink() {
                    if ($('.red-arrow-cta').length > 0) {
                        $('.red-arrow-cta').each(function () {
                            if ($(this).children('p').length > 0) {
                                var totalWidth = $(this).children('p').width() + 50;
                                $(this).css('width', totalWidth);
                            }
                        })
                    }
                }

                function createCalendar(events) {
                    //Create calendar and list of events
                    var calendar = $('.calendar-container').clndr({
                        template: $('#calendar-template').html(),
                        showAdjacentMonths: false,
                        events: events,
                        doneRendering: function () {
                            if ($('.calendar-container').find('.today').length > 0) {
                                var today = $('.calendar-container').find('.day.today').attr('class').split(' ');
                                var date;
                                today.forEach(function (class_name) {
                                    if (class_name.indexOf('calendar-day') > -1) {
                                        date = class_name.replace('calendar-day-', '');
                                    }
                                });
                                $('.calendar-container').find('.day.today').attr('data-current', 'date');
                                $('.calendar-container').find('.day.today .day-number').append('<span' +
                                    ' class="screen-reader-text">' + date + '</span>');
                            }
                        }
                    });
                }

                function clndrEvents() {
                    //Fix calendar on scroll
                    var calendar_waypoint = new Waypoint({
                        element: '.events-section',
                        handler: function () {
                            $('.clndr-body').addClass('fixed');
                        },
                        offset: '25%'
                    });

                    $(document).on('scroll', function () {
                        var scrollTop = $(window).scrollTop();
                        var staticPoint = $('.events-section').offset().top - ($(window).height() * .25);
                        if (scrollTop <= staticPoint) {
                            $('.clndr-body').removeClass('fixed');
                        } else {
                            var clndrBottom = ($(window).width() * .25) + $(".clndr-body").height();
                            if ((clndrBottom + scrollTop) >= ($("footer").offset().top + $("header").height() - 20) && $(".event-item").length > 0) {
                                $(".clndr-body.fixed").addClass("bottom-page");
                            } else {
                                $(".clndr-body.fixed").removeClass("bottom-page");
                            }
                        }

                        //Remove added padding from clicked event
                        //Meant to account for header on event linking
                        if (clickedDate !== "") {
                            var scrollTop = $(window).scrollTop();
                            if (prevTop > scrollTop && dateTop <= prevTop) {
                                $(clickedDate).removeAttr("style");
                                if ($(clickedDate).length > 0) {
                                    window.scrollTo(0, ($(clickedDate).offset().top - headerHeight));
                                }
                                setTimeout(function () {
                                    clickedDate = "";
                                }, 1);
                            }
                        }
                    });
                    var clickedDate = "";
                    var prevTop, dateTop, headerHeight;
                    $(".clndr").on("click", ".day", function () {
                        if ($(this).hasClass("event")) {
                            $(".event-listing ul li").removeAttr("style");
                            var eventID = $(this).attr('href');
                            headerHeight = $('header').height();
                            prevTop = $(eventID).offset().top;
                            dateTop = prevTop;
                            setTimeout(function () {
                                clickedDate = eventID;
                                if (($(document).scrollTop() + headerHeight) >= $(".event-listing ul").children(eventID).offset().top) {
                                    $(".event-listing ul").children(eventID).css("padding-top", headerHeight);
                                    $(".event-listing ul").addClass("anchor");
                                }
                            }, 10);
                        }
                    });

                    $(".clndr").on("click", ".clndr-body .month-button", function () {
                        setTimeout(function () {

                            //Style red arrow cta
                            if ($('.red-arrow-cta').length > 0) {
                                $('.red-arrow-cta').each(function () {
                                    if ($(this).children('p').length > 0) {
                                        var totalWidth = $(this).children('p').width() + 50;
                                        $(this).css('width', totalWidth);
                                    }
                                })
                            }

                            //Set Calendar body position after month change
                            var scrollTop = $(window).scrollTop();
                            if (scrollTop >= ($(".clndr-body").offset().top - ($(window).height() * .3))) {
                                var eventTop = $('.events-section').offset().top - $('header').height();
                                window.scrollTo(0, eventTop);
                                if ($(".event-item").length > 0) {
                                    $('.clndr-body').addClass('fixed');
                                }
                                $(".event-item").removeAttr("style");
                            }
                        }, 10);
                    })

                }
            }
        },

        'error404': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

            }
        },

        'page_template_template_inquiry': {
            init: function () {
                //JavaScript to be fired on all pages
            },
            finalize: function () {
                //JavaScript to be fired on all pages, after specific JS is fired

                //Select2
                $(document).ready(function () {
                    $("#form_9f298c9a-cb12-4074-abdd-96de390ef725_m").select2();
                    $("#form_9f298c9a-cb12-4074-abdd-96de390ef725_d").select2();
                    $("#form_9f298c9a-cb12-4074-abdd-96de390ef725_y").select2();
                    $("#form_555bfb86-18ba-4104-bce5-b0ef08131613").select2();
                    $("#form_0e034bc6-3c9a-465a-88de-e47b5428370f").select2();
                });
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
